/**
* Print styles.
* Taken from https://github.com/h5bp/html5-boilerplate
*/

@page {
	size: A4;
	margin: 2cm;

	@bottom-center {
		content: counter(page);
	}

	// counter-reset: footnote 0;
	// counter-increment: footnote;
}

@media print and (color) {
	* {
		-webkit-print-color-adjust: exact !important;
	}
}

@media print and (color) {

	*,
	*:before,
	*:after {
		background: transparent !important;
		color: #000 !important;
		// Black prints faster
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		text-shadow: none !important;
		-webkit-print-color-adjust: exact !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	// a[href]:after {
	// 	content: " ("attr(href) ")";
	// }

	// abbr[title]:after {
	// 	content: " ("attr(title) ")";
	// }

	/*
	* Don't show links that are fragment identifiers,
	* or use the `javascript:` pseudo protocol
	*/

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	// a[href] {
	// 	float: footnote;

	// 	&::footnote-call {
	// 		/* controls the footnote markers in the main page text */
	// 		content: counter(footnote, decimal) " ";
	// 	}

	// 	&::footnote-marker {
	// 		/* controls the footnote markers in the footnote section */
	// 		content: counter(footnote, lower-roman);
	// 	}
	// }

	// a::after {
	// 	content: attr(href);
	// 	float: footnote;
	// 	font-size: 0.6rem;
	// 	text-align: left;
	// 	color: inherit;
	// }

	pre {
		white-space: pre-wrap !important;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	/*
	* Printing Tables:
	* http://css-discuss.incutio.com/wiki/Printing_Tables
	*/

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	/*
  * blockquote
  */
	blockquote {
		border: none;
	}

	// Display sections inside main
	main section {
		display: flex !important;
		margin-right: 15px;
	}

	// Override .container styling from other print file?
	.container {
		min-width: 100% !important;
	}

	/*
	* Background colors
	*/
  [class*="bg-"] {
    background: unset !important;
  }

	/*
	* Spacing
	*/
	.section-padding {
		padding: 0;
	}

	/*
	* Buttons
  */
	.btn {
		&__link {
			.arrow {
				&::after {
					display: none;
				}
			}
		}

		&__primary,
		&__selfservice {
			display: none !important;

			a {
				display: none;
			}
		}
	}

	.btn__link-arrow--short,
	.btn__link {
		display: none;
	}

	/*
	* Heros
  */
	.hero {
		.hero__article {
			margin-bottom: 30px;

      &[class*="bg-"] {
        padding-bottom: 0;
      }

			.hero__article__text {
				padding-top: 0;
			}
		}

		&.hero__search-bar {
			.search-bar--small {
				display: none;
			}
		}
	}

	.hero__event {
		.hero__event__content {
			padding-bottom: 0;

			ul {
				height: auto !important;
			}
		}
	}

	.hero.hero__text-box,
	.hero__carousel {
		.hero-inner {
			margin-bottom: 0 !important;
			background-color: #fff !important;
			border: 1px solid #000;
		}
	}

	.hero__carousel .hero {
		margin-bottom: 0 !important;
	}

	.hero__multi-boxes {
		height: 450px;

		.hero__multi-boxes__box {
			background-color: #fff !important;
			border: 1px solid #000;

			.span__link {
				display: none;
			}
		}
	}

	/*
	* Section dividers
  */
	.section-divider {
		display: none;
	}

	/*
	* Banners
  */
	.banner__cta {
		display: none;
	}

	/*
	* Article wrapper
	*/
	.article-wrapper {
		padding-bottom: 0 !important;
	}

	/*
	* Accordion
  */
	.accordion {
		.accordion__toggle-btn {
			display: none;
		}

		&-header {
			padding: 1.5em 0 15px 0;

			&::before {
				border-bottom: none;
			}

			&:hover {
				padding-left: 0;
			}
		}

		&-body {
			display: block !important;

			&__contents {
				padding-top: 0 !important;
				padding-left: 0 !important;
			}
		}

		&.accordion__colored-backgrounds {
			&:after {
				display: none;
			}

			.accordion__title,
			.accordion__sub-title {
				padding: 0 0 30px 0;
			}

			.accordion-header {
				padding-left: 0 !important;
			}

			.accordion__item {
				border-top: 1px solid #000;

				&::before {
					width: 100%;
					border-bottom: none;
				}

				&:last-child {
					padding-bottom: 0;
					border-bottom: 1px solid #000;
				}

			}
		}
	}

	/*
	* Lists
	*/
	.list__links {
		li {
			padding-left: 0 !important;
			margin-left: 0 !important;
		}
	}

	/*
	* Cards
	*/
	.card {

		&.card__meeting,
		&.card__portrait,
		&.card__person,
		&.card__list,
		&.card__image,
		&.card__event {
			text-decoration: none;

			.arrow {
				display: none;
			}
		}

		&.card__meeting {
			min-height: auto;
			padding: 0;
		}

		&.card__portrait,
		&.card__person {

			.card__portrait__text,
			.card__person__text {
				padding: 15px 0 0 0;
			}
		}

		&.card__business {
			border: 1px solid #000;

			.card__business__text {
				padding: 0 0 0 15px;
			}
		}

		&.card__person {
			.card__person__image {
				height: 100%;
				width: 100%;

				img {
					height: 300px;
					width: 260px;
				}
			}

			a {
				display: none;
			}
		}

		&.card__image {
			margin-bottom: 30px !important;

			&.card__image--small {
				height: 100% !important;
			}

			.card__image__media {
				position: relative;
			}

			.card__image__content {
				padding: 15px 0 0 0;
				height: auto;
			}
		}

		&.card__event {
			.card__event__image__date {
				background-color: #fff !important;
				border: 1px solid #000;
			}

			.card__event__content {
				padding: 15px 0 0 0;
			}
		}
	}

	/*
	* Info-boxes
	*/
	.info-box {
		&-article__contact {
			margin-top: 40px;
			border-top-width: 2px;
			border-top-color: #000;

			.info-box-article__icon {
				display: none;
			}
		}

		&.info-box__publications {
			.list li a {
				flex-direction: column;
			}

			.arrow {
				display: none;
			}

			.list__image {
				max-width: 100%;
				width: 100%;
				margin-right: 0;
			}
		}
	}

	.info-box__links__icon {
		.info-box__links__icon__item__header:after {
			display: none;
		}

		.info-box__links__icon__item__body {
			display: flex;
		}
	}

	/*
	* Media box
  */
	.media-box {
		flex-direction: column;

		&.media-box--full-width {
			&.reverse .row {
				flex-direction: row;
			}

			.media-box__media {
				position: relative;
				width: 100vw;
				height: 450px;

				img {
					height: 450px;
					width: auto;
				}
			}
		}

		.media-box__text {
			padding: 15px 0 !important;
			margin: 0;
		}
	}

	/*
	* Events
  */
	.events {
		.arrow {
			display: none;
		}
	}

	/*
	* Link boxes
  */
	.link-boxes {

		.link-box__icon,
		.link-box__arrow {
			text-decoration: none;
			padding: 15px 0 0 0;
		}

		.arrow {
			display: none;
		}
	}

	/*
  * Image
  */
	.image {
		img {
			max-height: 480px;
		}
	}

	/*
  * video
  */
	.video {
		display: none;

		svg {
			display: none;
		}

		p.caption {
			margin-top: 20px !important;
		}
	}

	/*
	* Owl carousels
	*/
	.owl-carousel {
		.owl-nav,
		.owl-dots {
			display: none;
		}

		.owl-stage-outer {
			height: unset !important;
		}
	}

	/*
	* Side menu
	*/
	.side-menu {
		display: none;
	}

	/*
	* Fact box
	*/
	.fact-box {
		border: 1px solid #000;

    &.fact-box--collapsible {
      .fact-box__content {
        margin-bottom: 30px;
      }

      .read-more-button {
        display: none;
      }

      .closed {
        max-height: 100% !important;
        -webkit-mask-image: unset;
      }
    }

		.fact-box__title {
			padding-bottom: 0;

			&:after {
				display: none;
			}
		}
	}

	.fact-box,
	.card.card__business {
		width: 99%;
	}

	/*
	* Side boxes
  */
	.side-boxes__most-popular {
		padding: 0;

		ol {
			padding-left: 0;
		}

		a {
			flex-direction: column;

			span {
				margin-top: 0 !important;
			}
		}
	}

	.side-boxes__counter {
		.side-boxes__counter__content p {
			width: auto;
		}
	}

	/*
	* Titles
  */
	.title {
		.title__icon .title__icon__bg {
			border: 1px solid #000;
		}
	}

	/*
	* Tabels
  */
	.table {
		th {
			text-align: left;
		}
	}

	// List links
	.list__link {
		padding-left: 0 !important;
		margin-left: 0 !important;

		&::before {
			display: none;
		}
	}

	/*
	* Components and elements not relevent for print
  */
	.breadcrumb,
	.breadcrumb-container,
	.article-anchor-menu,
	.service-menu,
	.link-boxes__article,
	.info-box-article__links,
	.info-box-article__documents,
	progress,
	.feedback-banner,
	.owl-card__portrait-carousel,
	.btn-container,
	.btn,
	.filter-menu,
	footer,
	header,
	.picker-button,
	.top-link__container,
	.info-box-article__comments,
	#Coi-Renew,
#CybotCookiebotDialog,
#cookie-information-template-wrapper  {
		display: none !important;
	}

}
